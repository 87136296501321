<template>
  <div class="Article">
    <el-container>
      <el-aside width="150px">
        <ul>
          <li
            @click="reload(item.name,index)"
            v-for="(item, index) in message"
            :key="index"
            :class="{check:checkIndex==index}"
          >
            {{ item.name }}
          </li>
        </ul>
      </el-aside>
      <el-main
        ><Content :KeyWords.sync="messageone" v-if="isfresh"></Content
      ></el-main>
    </el-container>
  </div>
</template>
<script>
import Content from "../../components/page";

export default {
  components: {
    Content,
  },
  provide: function () {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      checkIndex: 0,
      message: [
        { name: "移动端热门文章" },
        { name: "App端热门文章" },
        { name: "App端资讯置顶" },
      ],
      messageone: "移动端热门文章",
      isfresh: true,
    };
  },
  methods: {
    reload(name,index) {
      this.messageone = name;
      this.isfresh = false;
      this.checkIndex=index;
      this.$nextTick(() => {
        this.isfresh = true;
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.Article {
  ul {
    width: 132px;
    height: calc(100vh - 100px);
    background: #fff;

    li {
      padding: 5px 0 5px 20px;
      cursor: pointer;
    }

    li:hover {
      color: #409eff;
      background: #ecf5ff;
      padding-left: 25px;
    }

    .check {
      color: #409eff;
      background: #ecf5ff;
      padding-left: 25px;
    }
  }

  // height 100%
  .el-container {
    // height 100%
  }

  .el-main {
    background: #fff;

    /deep/.el-tabs__content {
    }
  }
}
</style>